import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData
} from '../../../domain/utils/contentUtils';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { theme } from '../../../domain/theme';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { ParagraphWrapper } from '../ParagraphWrapper';

const FocusedHeroPackageInner = ({ data, content, index, isAboveTheFold }) => {
  const [breakpoint] = useBreakpoint();

  const isHandheld = ['tablet', 'mobile'].includes(breakpoint.name);
  const previousBlockIsFocusedHeroPackageOrContentPackage1 =
    index === 1 &&
    ['ParagraphContentPackage1', 'ParagraphFocusedHeroPackage'].includes(
      content.mainContent?.[0]?.entity?.type
    );
  const canBeAnimated =
    isHandheld && previousBlockIsFocusedHeroPackageOrContentPackage1;

  return (
    <ContentPackageWrapper
      backgroundColor={
        data.backgroundColor === 'black' ? 'true_black' : data.backgroundColor
      }
      extraStyles={css`
        ${facePaint({
          'contain-intrinsic-size': ['0 599px', '0 590px', '0 737px', '0 922px']
        })}
        margin-bottom: 0;
        ${theme.mq.tablet} {
          margin-bottom: 0;
        }
      `}
    >
      <CardItemVerticalLegacy
        data={removeAuthorsAndDateFromCardData(
          mapContentToCardData(data.card.entity)
        )}
        size="focused"
        isAboveTheFold={isAboveTheFold}
        backgroundColor={data.backgroundColor}
        textAlign={data.imageAlign}
        canBeAnimated={canBeAnimated}
      />
    </ContentPackageWrapper>
  );
};

FocusedHeroPackageInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  isAboveTheFold: PropTypes.bool
};

FocusedHeroPackageInner.defaultProps = {
  isAboveTheFold: false
};

export const FocusedHeroPackage = ParagraphWrapper(FocusedHeroPackageInner);
